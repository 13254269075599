import React, { useEffect, useState } from 'react'
import { Container, Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import useIntersectionObserver from '../../components/userIntersectionObserver';
import { FaChrome } from 'react-icons/fa';
import ChromeIcon from './chrome_icon.png'
function Hero() {
  const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [isMobile, setIsMobile] = useState(false);
  useEffect(()=>{
    const updateMobileStatus = () => {
      if(window.innerWidth < 640) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    updateMobileStatus();
    window.addEventListener('resize',updateMobileStatus);

    return () => window.removeEventListener('resize',updateMobileStatus);
  })
  return (
    <Container className='flex flex-col justify-center items-center'>
      <div className='h-svh flex flex-col justify-center items-center'>
        <div className='flex-none cabin_sketch py-20 '>
          <h1 className={`text-4xl sm:text-6xl text-center pb-1 fadeInUp visible`} style={{ '--animation-duration': '0.4s' }}>Supercharge Your LinkedIn Outreach with</h1>
          <h1 className={`text-center text-4xl sm:text-6xl fadeInUp pt-1 visible`} style={{ '--animation-duration': '0.6s' }}>AI-Driven Personalization</h1>
        </div>
        <div className='my-10 w-full flex flex-col sm:flex-row items-center justify-center'>
          <button className={`downloadButton w-72 py-2.5 my-1 mx-3 fadeInUp text-lg sm:text-2xl flex items-center justify-center visible`} style={{ '--animation-duration': '0.8s' }} onClick={() => {
            window.open('https://chromewebstore.google.com/detail/engagex-the-linkedin-plug/cmapiaejeihjngekanombmnhbfggdali', '_blank')
          }}>
            <FaChrome className='mx-2' />
            Add EngageX to Chrome
          </button>
          <a href="#features" className={`learnMoreButton mx-3 my-1 w-72 py-2.5 fadeInUp text-lg sm:text-2xl flex items-center justify-center visible`} style={{ '--animation-duration': '1s'}}>
            Learn More
          </a>
        </div>
      </div>
      <div className={`row gy-4 mt-5 py-20`} id="learn__More">
        <div ref={elementRef} className={`col-md-6 col-lg-3 fadeInUp ${isVisible && !isMobile && 'visible'} ${isMobile && 'no-animation'}`} style={{ '--animation-duration': '0.9s' }}>
          <div className="icon-box">
            <div className="icon"><i className="bi bi-chat-left-text-fill"></i></div>
            <h4 className="text-lg mb-3 cabin_sketch">Connection Requests</h4>
            <p className="description">Instantly create tailored connection requests that get noticed.</p>
          </div>
        </div>
        {/* <!-- End Icon Box --> */}

        <div ref={elementRef} className={`col-md-6 col-lg-3 fadeInUp ${isVisible && !isMobile && 'visible'} ${isMobile && 'no-animation'}`} style={{ '--animation-duration': '1.1s' }}>
          <div className="icon-box">
            <div className="icon"><i className="bi bi-stickies-fill"></i></div>
            <h4 className="text-lg mb-3 cabin_sketch">Personalized Notes</h4>
            <p className="description">Boost acceptance rates with AI-generated, personalized notes.</p>
          </div>
        </div>
        {/* <!--End Icon Box --> */}

        <div ref={elementRef} className={`col-md-6 col-lg-3 fadeInUp ${isVisible && !isMobile && 'visible'} ${isMobile && 'no-animation'}`} style={{ '--animation-duration': '1.3s' }}>
          <div className="icon-box">
            <div className="icon"><i className="bi bi-archive-fill"></i></div>
            <h4 className="text-lg mb-3 cabin_sketch">Context-Specific</h4>
            <p className="description">Engage more effectively with context-specific message suggestions.</p>
          </div>
        </div>
        {/* <!--End Icon Box --> */}

        <div ref={elementRef} className={`col-md-6 col-lg-3 fadeInUp ${isVisible && !isMobile && 'visible'} ${isMobile && 'no-animation'}`} style={{ '--animation-duration': '1.5s' }}>
          <div className="icon-box">
            <div className="icon"><i className="bi bi-person-fill"></i></div>
            <h4 className="text-lg mb-3 cabin_sketch">Profile Analysis</h4>
            <p className="description">Save valuable time by automating profile analysis.</p>
          </div>
        </div>
        {/* <!--End Icon Box --> */}
      </div>
    </Container>
  )
}

export default Hero;