import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Reports from "./pages/Reports";
import Header from "./components/Header";
import Messages from "./pages/messages";
import Signup from "./pages/Signup";
import SocialAuth from "./pages/socialauth";
import Offerings2 from "./pages/Offerings2";
import "bootstrap/dist/css/bootstrap.min.css";
import Profile from "./pages/profile";
import StateProvider from "./StateProvider";
import "./App.css";
import LandingPage from "./pages/LandingPage/LandingPage";

import Icon from "./pages/Extension/Icon";

// Include Google Fonts link
const googleFontsLink = document.createElement("link");
googleFontsLink.href =
  "https://fonts.googleapis.com/css2?family=Cabin+Sketch&display=swap";
googleFontsLink.rel = "stylesheet";
document.head.appendChild(googleFontsLink);

let a_t = localStorage.getItem("access_token");
if (a_t == null) {
  a_t = "Haha";
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);

  useEffect(() => {
    const username = localStorage.getItem("goggleFirstName");
    setIsLoggedIn(!!username);
    window.addEventListener("message", handleMessage);

    // Cleanup function
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event) => {
    const { data } = event;
    if (data.isLoggedIn !== undefined) {
      setIsLoggedIn(data.isLoggedIn);
    }
  };

  return (
    <StateProvider>
      <Router>
        <Routes>
          <Route path="/signup" element={isLoggedIn ? <Home /> : <Signup />} />
          <Route path="/home" exact element={<SocialAuth />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/offerings" element={isLoggedIn ? <Offerings2 /> : <Signup />} />
          <Route path="/messages" element={isLoggedIn ? <Messages /> : <Signup />} />
          <Route path="" element={(isLoggedIn) ?
            <Home
              stepOne={stepOne}
              stepTwo={stepTwo}
              setStepOne={setStepOne}
              setStepTwo={setStepTwo}
            /> :
            <LandingPage />} />
          <Route
            path="/loggedHome"
            element={
              isLoggedIn ?
                <Home
                  stepOne={stepOne}
                  stepTwo={stepTwo}
                  setStepOne={setStepOne}
                  setStepTwo={setStepTwo}
                /> :
                <Signup />
            }
          />
          <Route
            path="/profile"
            element={
              isLoggedIn ?
                <Profile
                  stepOne={stepOne}
                  stepTwo={stepTwo}
                  setStepOne={setStepOne}
                  setStepTwo={setStepTwo}
                /> :
                <Signup />
            }
          />
          <Route
            path="/extension/icon"
            element=<Icon />
          />
          <Route
            path="/landingpage"
            element=<LandingPage/>
          />
        </Routes>
      </Router>
    </StateProvider>
  );
}

export default App;