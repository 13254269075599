import { getCookie } from "../utils";
const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

const a_t = getCookie("accessToken");
export const getMessages = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + a_t);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${baseUrl}/linkedin/generated_text_all`,
      requestOptions
    );
    const data = await response.json();
    // console.log("data", data);
    if (data.code && data.code === "token_not_valid") {
      return { error: true, message: "Session expired, please login again" };
    } else {
      return data.data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { error: true, message: "Error fetching data. Please try again." };
  }
};
