export const formatDateTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const date = dateTime.toDateString();
  const time = dateTime.toLocaleTimeString();
  return { date, time };
};
export const getCookie = (name) => {
  // Split cookies into individual parts
  var cookies = document.cookie.split(";");

  // Iterate over each cookie to find the one with the specified name
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];

    // Remove leading spaces
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }

    // Check if this cookie has the specified name
    if (cookie.indexOf(name + "=") === 0) {
      // Extract and return the value of the cookie
      return cookie.substring(name.length + 1, cookie.length);
    }
  }

  // If cookie with specified name is not found, return null
  return null;
};
export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
