import React, { useState } from "react";
import GlobalContext from "./GlobalContext";
//const GlobalContext = React.createContext();

export default function MainProvider({ children }) {
  const [data, setData] = useState({ stepOne: false, stepTwo: false });

  const mutate = (update) => {
    const newData = { ...data, ...update };
    setData(newData);
  };

  return (
    <GlobalContext.Provider value={{ data, mutate }}>
      {children}
    </GlobalContext.Provider>
  );
}
