import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react'
import './landingPage.css';
import './main.css'
import Hero from './Hero';
import Features from './Features';
import Pricing from './Pricing';
import { Container } from '@mui/material';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';


function Header() {
  return (
    <nav className="header drop-shadow-md px-1.5 py-2">
      <Container>
        <img
          src={`${process.env.PUBLIC_URL}/engagex_logo.jpeg`}
          alt="EngageX Logo"
          className="logo-image"
          style={{ width: "130px", height: "60px" }}
        />
      </Container>
    </nav>

  );
}
function LandingPage() {
  return (
    <div>
      <Helmet>
        <title>EngageX</title>
        <meta property="og:title" content="EngageX" />
        <meta property="og:image" content='../../public/engagex_logo.jpeg' />
        <meta property="og:url" content="https://engagex.launchxlabs.ai/" />
      </Helmet>
      <Header />
      <div className='hero'>
        <Hero />
      </div>
      <div className='features' id='features'>
        <Features />
      </div>
      <div className='pricing_table' id="pricing">
        <Pricing />
      </div>
      <Footer />
    </div>
  )
}

export default LandingPage;