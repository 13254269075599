import React from "react";
import { deleteCookie } from "../utils";
import { FaBell, FaEnvelope, FaUserPlus, FaSignOutAlt } from "react-icons/fa";
import "./Header.css";

const handleLogout = () => {
  // Perform logout-related tasks, such as clearing localStorage
  localStorage.clear();

  // Optionally, perform other logout-related tasks
  // setUsername("");
  // ...
  deleteCookie("accessToken");
  // Redirect to the home page after logout
  window.location.href = "/signup";
};

export default function Header() {
  return (
    <nav className="header drop-shadow-md px-3 py-1.5">
      <img
        src={`${process.env.PUBLIC_URL}/engagex_logo.jpeg`}
        alt="EngageX Logo"
        className="logo-image"
        style={{ width: "130px", height: "60px" }}
      />
      <ul className="header-menu">
        <li className="flex items-center justify-center">
          <button onClick={handleLogout} className="btn btn-outline-warning flex items-center justify-center">
            Logout <FaSignOutAlt className="ml-2"/>
          </button>
        </li>
      </ul>
    </nav>
  );
}