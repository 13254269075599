import React from "react";
import "./pages.css";
import Form2 from "../components/form_testing";
import { Helmet } from "react-helmet";

function Offerings2() {

  return (
    <div className="offerings">
    <Helmet>
      <title>Offerings - EngageX</title>
      <meta property="og:title" content="Offerings - EngageX"/>
      <meta property="og:image" content='../../public/engagex_logo.jpeg'/>
      <meta property="og:url" content="https://engagex.launchxlabs.ai/offerings"/>
    </Helmet>
      <h2 className="messages-header text-center">Offerings</h2>
      <Form2 />
    </div>
  );
}

export default Offerings2;
