const pricing = [
  {
    animation_duration: '0.5s',
    title : "Free",
    desc: "Best option for personal use & for your next project.",
    price : "$0",
    benefits: [
      "1 User",
      "20 Credits"
    ],
    disadvantages: [
      "Limited Feaures",
      "Only Default offering",
      "No 24/7 support"
    ]
  },
  {
    animation_duration: '0.7s',
    title : "Solo",
    desc: "Relevant for single user with extended & premium support.",
    price : "$19.99",
    benefits : [
      "1 User",
      "250 Credits",
      "All Features",
      "Upto 5 custom offerings",
    ],
    disadvantages: [
      "No 24/7 support"
    ],
    paymentLink: "https://www.paypal.com/ncp/payment/JYQ56876Y9AYL"
  },
  {
    animation_duration: '0.9s',
    title: "Enterprise/Business",
    desc: "Best for large scale uses and extended redistribution rights.",
    price: "$69.99",
    benefits : [
      "Ulimited Users",
      "1800 Credits",
      "All Features",
      "Unlimited Custom Offerings",
      "24/7 support"
    ],
    paymentLink: "https://www.paypal.com/ncp/payment/ZYL7T8STH72HJ",
  }
]

export {pricing};