import { getCookie } from "../utils";
const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

const a_t = getCookie("accessToken");
export const checkUserData = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${a_t}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${baseUrl}/linkedin/user_profile_data_check`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok && data.status === "success") {
      return data;
    } else {
      return { error: true, message: "User has not entered data" };
    }
  } catch (error) {
    console.error("Error checking user data:", error);
  }
};
export const getContexts = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${a_t}`);

  const requestOptions = {
    method: "GET", // Change to GET method
    headers: myHeaders,
    redirect: "follow",
  };

  const res = await fetch(`${baseUrl}/linkedin/context_all?`, requestOptions);
  const result = await res.json();

  if (result.code && result.code === "token_not_valid") {
    return { error: true, message: "" };
  } else return result.data;
};

export const fetchData = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${a_t}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${baseUrl}/linkedin/user_check`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      // console.log("User data:", data);
      // setUserData(data.data);
      return data;
    } else {
      console.error("Failed to fetch user data:", data);
      return { error: true, message: "Failed to fetch user data:", data };
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return { error: true, message: "Error fetching user data:", data: error };
  }
};
