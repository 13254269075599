import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { formatDateTime, getCookie } from "../utils";
import { fetchData, getContexts, checkUserData } from "../api/home";
import { addContext } from "../api/offerings";
import { getMessages } from "../api/messages";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Row, Col, Tab } from "react-bootstrap";
import { TableCell, TableRow } from "@mui/material";
import { blue } from "@mui/material/colors";
import GlobalContext from "../GlobalContext";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// TODO: Change a_t of form_testing, messages and Home from hard code to cookie
let a_t = getCookie("accessToken");
//let a_t = localStorage.getItem('accessToken');
// let a_t = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEzMjAwMDg0LCJpYXQiOjE3MTEwNDAwODQsImp0aSI6ImE3ZDJkZjRlYTRkZTRkYjZiNzQwOWIxYzc3MTk5NDYxIiwidXNlcl9pZCI6MjV9.KKaqiWYcm4uJzoZpc6NM2JKo7pRKCCkoKIqpeIUpJHM";

// const formatDateTime = (dateTimeString) => {
//   const dateTime = new Date(dateTimeString);
//   const date = dateTime.toDateString();
//   const time = dateTime.toLocaleTimeString();
//   return { date, time };
// };

function Home() {
  const [numMessages, setNumMessages] = useState(0);
  const [lastMessageTime, setLastMessageTime] = useState("");
  const [lastMessageName, setLastMessageName] = useState("None");
  const [errorMessage, setErrorMessage] = useState(null);
  const [numContexts, setNumContexts] = useState(0);
  const [defaultOffering, setDefaultOffering] = useState("None");
  const [retryUserData, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const contextData = useContext(GlobalContext);

  const { data, mutate } = contextData;

  const { stepOne } = data;
  const handleDataFetch = async () => {
    const res = await getMessages();
    // console.log("messages", res);
    if (res && res.length > 0) {
      const lastMessage = res[res.length - 1];
      setNumMessages(res.length);
      setLastMessageTime(lastMessage.updated_at);
      setLastMessageName(lastMessage.prospect.linkedin_username);
      setErrorMessage(null);
    } else {
      setLastMessageTime("");
      setLastMessageName("");
    }
  };
  const handleCheckUser = async () => {
    const result = await checkUserData();
    if (result && result.error) {
      mutate({ stepOne: false });

      // checking for user data after every 5 seconds
      if (retryUserData < 4) {
        setTimeout(() => {
          setRetryCount(retryUserData + 1);
          handleCheckUser();
        }, 5000);
      }
    } else {
      mutate({ stepOne: true });
    }
  };

  const handleGetContexts = async () => {
    try {
      const result = await getContexts();
      // console.log("Contexts", result);
      if (result) {
        setNumContexts(result.length);
        if (result.length === 0 && numContexts === 0) {
          setLoading(true);
          // console.log("default context being added from useEffect");
          await addContext("Default", "Connection request", a_t);
          // handleGetContexts();
          setErrorMessage(null);
        }
        // mutate({ stepTwo: true });
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Session expired, please login again')
    }
  };
  // const fetchData = async () => {
  //   try {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${a_t}`);

  //     const requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     const response = await fetch(
  //       `${BACKEND_API_URL}/linkedin/user_check`,
  //       requestOptions
  //     );
  //     const data = await response.json();

  //     if (response.ok) {
  //       setUserData(data.data);
  //     } else {
  //       console.error("Failed to fetch user data:", data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    handleDataFetch();
    handleGetContexts();
    handleCheckUser();

    const defaultOfferingCookie = getCookie("default_offering");
    setDefaultOffering(defaultOfferingCookie);
  }, []); // Fetch data on component mount

  // //UseEffect specifically for the stepOne and stepTwo
  // useEffect(() => {
  //   handleUserCheck();
  //   handleGetContexts();
  // }, []); // Fetch data on component mount

  //First time registration logic
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Authorization", `Bearer ${a_t}`);

  //       const requestOptions = {
  //         method: "GET",
  //         headers: myHeaders,
  //         redirect: "follow",
  //       };

  //       const response = await fetch(
  //         `${BACKEND_API_URL}/linkedin/user_check`,
  //         requestOptions
  //       );
  //       const data = await response.json();

  //       if (response.ok) {
  //         setUserData(data.data);
  //       } else {
  //         console.error("Failed to fetch user data:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  //   getContexts();
  //   checkUserData();
  // }, []);
  // console.log(numContexts, "numContexts", stepOne);
  // console.log(stepOne && parseInt(numContexts) > 0);
  return (
    <div className="home-container">
      <Helmet>
        <title>Home - EngageX</title>
        <meta property="og:title" content="Home - EngageX" />
        <meta property="og:image" content='../../public/engagex_logo.jpeg' />
        <meta property="og:url" content="https://engagex.launchxlabs.ai/loggedHome" />
      </Helmet>
      {errorMessage ? <p className="error-message">{errorMessage}</p> :
        <>
          <Header />
          <Navbar />
        </>
      }

      {!errorMessage && !loading && !(stepOne && numContexts > 0) && (
        <div>
          <h2 className=" messages-header text-center">
            Please complete the registration!
          </h2>
          <div>
            <TableRow>
              <TableCell>
                <p className="mb-2 lead neucha-regular ">
                  {/* <strong className="headings-messages">Step One:</strong>  */}
                  Go to your LinkedIn profile, and Submit your profile through EngageX.
                </p>
              </TableCell>
            </TableRow>
          </div>

          <div>
            <TableRow>
              {/* <TableCell>
                <input
                  type="checkbox"
                  id="stepTwo"
                  style={{ marginRight: "8px", transform: "scale(1.5)" }}
                  checked={true}
                  // onChange={() => setStepTwo(!stepTwo)}
                  // disabled
                />
              </TableCell> */}
              <TableCell>
                <p className="mb-2 lead neucha-regular ">
                  {/* <strong className="headings-messages">Step Two:</strong>  */}
                  You can add some offerings in the Offerings section.
                </p>
              </TableCell>
            </TableRow>
          </div>
        </div>
      )}

      {/* Font imports */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&family=Neucha&family=Pacifico&family=Ubuntu:wght@300;400;500;700&display=swap');
        `}
      </style>

      {!errorMessage && !loading && stepOne && parseInt(numContexts) > 0 && (
        <div>
          <h1 className="display-5 mb-lg-4 mt-4 text-center orange-color">
            Dashboard
          </h1>

          <div className="dashboard-container">
            <h2 className=" messages-header text-center">Messages</h2>
            <Row className="dashboard-row">
              {/* First Card: Number of messages sent */}
              <Col>
                <Card className="dashboard-card highlighted">
                  <Card.Body>
                    <Card.Title className="dashboard-card-title neucha-regular">
                      Number of Messages Generated
                    </Card.Title>
                    <Card.Text className="dashboard-card-text">
                      {numMessages}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {/* Second Card: Last message sent at */}
              <Col>
                <Card className="dashboard-card highlighted">
                  <Card.Body>
                    <Card.Title className="dashboard-card-title neucha-regular">
                      Last Message Generated
                    </Card.Title>
                    <Card.Text className="dashboard-card-text">
                      {numMessages ? (
                        <>
                          {" "}
                          On {formatDateTime(lastMessageTime).date} at{" "}
                          {formatDateTime(lastMessageTime).time}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {/* Third Card: Last message sent to */}
              <Col>
                <Card className="dashboard-card highlighted">
                  <Card.Body>
                    <Card.Title className="dashboard-card-title neucha-regular">
                      Last Message Generated for
                    </Card.Title>
                    <Card.Text className="dashboard-card-text">
                      {numMessages ? lastMessageName : "N/A"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <h2 className=" messages-header text-center">Offerings</h2>
            <Row className="dashboard-row">
              {/* First Card: Number of messages sent */}
              <Col xs={4}>
                <Card className="dashboard-card highlighted ">
                  <Card.Body>
                    <Card.Title className="dashboard-card-title neucha-regular">
                      Number of Offerings{" "}
                    </Card.Title>
                    <Card.Text className="dashboard-card-text">
                      {numContexts}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {/* Second Card: Last message sent at */}
              <Col xs={8}>
                <Card className="dashboard-card highlighted">
                  <Card.Body>
                    <Card.Title className="dashboard-card-title neucha-regular">
                      Default offering
                    </Card.Title>
                    <Card.Text className="dashboard-card-text">
                      {defaultOffering}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {/* Third Card: Last message sent to */}
            </Row>
          </div>
        </div>
      )}

      {/* <button className='custom-button home-button-offering' onClick={()=>{navigate('/offerings')}}>Add custom offering</button> */}
    </div>
  );
}

export default Home;
