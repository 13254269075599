import React, { useEffect, useState } from "react";
import { getMessages } from "../api/messages";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./loader.css";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const getAccessToken = () => {
  const cookies = document.cookie.split("; ");
  const accessTokenCookie = cookies.find((cookie) =>
    cookie.startsWith("accessToken=")
  );

  if (accessTokenCookie) {
    const [, accessToken] = accessTokenCookie.split("=");
    return accessToken;
  }

  return null;
};

//Expiry time cookies
function getCookie(name) {
  // Split cookies into individual parts
  var cookies = document.cookie.split(";");

  // Iterate over each cookie to find the one with the specified name
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];

    // Remove leading spaces
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1);
    }

    // Check if this cookie has the specified name
    if (cookie.indexOf(name + "=") == 0) {
      // Extract and return the value of the cookie
      return cookie.substring(name.length + 1, cookie.length);
    }
  }

  // If cookie with specified name is not found, return null
  return null;
}
// TODO: Change a_t of form_testing, messages and Home from hard code to cookie
let a_t = getCookie("accessToken");
// let a_t = localStorage.getItem("access_token");
if (a_t == null) {
  a_t = "Haha";
}

function Messages() {
  const [jsonData, setJsonData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleFetchData = async () => {
    const res = await getMessages();
    if (res.error) {
      setErrorMessage(res.message);
    } else {
      setJsonData(res || []);
      // console.log(jsonData);
    }
    setLoading(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + a_t);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${BACKEND_API_URL}/linkedin/generated_text_all`,
          requestOptions
        );
        const data = await response.json();

        if (data.code && data.code === "token_not_valid") {
          setErrorMessage("Session expired, please login again");
        } else {
          setJsonData(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("Error fetching data. Please try again.");
      } finally {
        // After fetching data, set loading to false
        setLoading(false);
      }
    };

    //fetchData();
    handleFetchData();
  }, []);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toDateString();
    const time = dateTime.toLocaleTimeString();
    return { date, time };
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Messages - EngageX</title>
        <meta property="og:title" content="Messages - EngageX" />
        <meta property="og:image" content='../../public/engagex_logo.jpeg' />
        <meta property="og:url" content="https://engagex.launchxlabs.ai/messages" />
      </Helmet>
      <h2 className="messages-header text-center">Messages Generated</h2>

      {/* {loading && <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>} */}
      {loading && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {!errorMessage && !loading && <>
        <Header/>
        <Navbar/>
        {jsonData
          .slice(0)
          .reverse()
          .map((message, index) => (
            <div key={index} className=" padding-needed message-item ">
              <p className="mb-2 lead neucha-regular ">
                <strong className="headings-messages">Prospect:</strong>{" "}
                <a
                  href={`https://linkedin.com/in/${message.prospect.linkedin_username}`}
                  target="_blank"
                  className="hyperlink-messages"
                >
                  {message.prospect.linkedin_username}
                </a>
              </p>
              <p className="mb-2 lead neucha-regular ">
                <strong className="headings-messages">Generated Text:</strong>{" "}
                {message.generated_text}
              </p>
              <p className="mb-2 lead neucha-regular ">
                <strong className="headings-messages">Date:</strong>{" "}
                {formatDateTime(message.updated_at).date}{" "}
                <strong className="headings-messages">and Time:</strong>{" "}
                {formatDateTime(message.updated_at).time}
              </p>
            </div>
          ))}
      </>}
    </div>
  );
}

export default Messages;
