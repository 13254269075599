import React, { useState, useEffect } from "react";
import "./pages.css";
import "./loader.css";
import "../components/form_offerings2.css";
import UpdateModal from "../components/UpdateModal";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Navbar from "../components/Navbar";


function getCookie(name) {
  // Split cookies into individual parts
  var cookies = document.cookie.split(";");

  // Iterate over each cookie to find the one with the specified name
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];

    // Remove leading spaces
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }

    // Check if this cookie has the specified name
    if (cookie.indexOf(name + "=") === 0) {
      // Extract and return the value of the cookie
      return cookie.substring(name.length + 1, cookie.length);
    }
  }

  // If cookie with specified name is not found, return null
  return null;
}

// TODO: Change a_t of form_testing, messages and Home from hard code to cookie
let a_t = getCookie("accessToken");
// let a_t = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5ODM2OTc5LCJpYXQiOjE3MTc2NzY5NzksImp0aSI6ImJkNWFiYjk0MDMwMTQwMGQ4ZDc4YWI4M2E4ZTk0MmUwIiwidXNlcl9pZCI6MTkxfQ.0TH3NrPr7SavhWmDG_Pt8smQG59e7nb5SJfOu0_vC-8";




const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

function Profile({ stepOne, stepTwo, setStepOne, setStepTwo }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${a_t}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${baseUrl}/linkedin/user_check`,
          requestOptions
        );
        const data = await response.json();

        if (response.ok) {
          setUserData(data.data);
        } else {
          console.error("Failed to fetch user data:", data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkUserData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${a_t}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${baseUrl}/linkedin/user_profile_data_check`,
          requestOptions
        );
        const data = await response.json();

        if (response.ok && data.status === "success") {
          setStepOne(true);
        } else {
          setStepOne(false);
        }
      } catch (error) {
        console.error("Error checking user data:", error);
      }
    };

    const getContexts = () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${a_t}`);

      const requestOptions = {
        method: "GET", // Change to GET method
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${baseUrl}/linkedin/context_all?`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.code && result.code === "token_not_valid") {
            setErrorMessage("Session expired, please login again");
          }

          if (result.data.length > 0) {
            setStepTwo(true);
          } else {
            setStepTwo(false);
          }
        })
        .catch((error) => console.error("error", error))
        .finally(() => {
          setLoading(false); // Set loading to false in the finally block
        });
    };

    fetchData();
    checkUserData();
    getContexts();
  }, [setStepTwo, setStepOne]);



  return (
    <div className="home-container">
      <Helmet>
        <title>Profile - EngageX</title>
        <meta property="og:title" content="Profile - EngageX" />
        <meta property="og:image" content='../../public/engagex_logo.jpeg' />
        <meta property="og:url" content="https://engagex.launchxlabs.ai/profile" />
      </Helmet>
      <h2 className="messages-header text-center">Profile</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {loading && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}

      {!errorMessage && !loading && userData && (<>
        <Header/>
        <Navbar/>
        <div className="profile-info">
          <p className="mb-2 lead neucha-regular ">
            <strong className="headings-messages">Name:</strong>{" "}
            {userData.first_name} {userData.last_name}
          </p>
          <p className="mb-2 lead neucha-regular ">
            <strong className="headings-messages">Email:</strong> {userData.email}
          </p>
          <p className="mb-2 lead neucha-regular ">
            <strong className="headings-messages">LinkedIn username:</strong> {userData.user_profile_confirmation ? userData.linkedin_username : ''}
            {/* <!-- Button trigger modal --> */}
            {userData.user_profile_confirmation &&
              <button type="button" className="btn btn-outline-warning mx-3" onClick={() => setModalShow(true)}>
                Update
              </button>
            }
          </p>
        </div>
      </>
      )}

      <UpdateModal
        show={modalShow}
        onHide={() => setModalShow(false)} />
    </div>
  );
}

export default Profile;
