import React from 'react'
import icon from './icon-34.png';
function Icon() {
  const handleClick = () => {
    window.parent.postMessage({type : 'openIframe'})
  }
  return (
    <button className='floating-button w-[40px]' onClick={handleClick}>
      <img src={icon} alt=""/>
    </button>
  )
}

export default Icon