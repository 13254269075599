/* eslint-disable react/jsx-no-target-blank */
import { Container } from '@mui/material'
import React from 'react'
import LaunchX from './LaunchX.svg';
function Footer() {
  return (
    <footer className="bg-gray-200 shadow">
      <Container className="w-full max-w-screen-xl mx-auto p-4 md:py-8 text-black">
        <div className="sm:flex items-center sm:justify-between justify-center">
          <div className='flex justify-center items-center'>
            <h1 className="text-xl">A Product by </h1>
            <a href="https://launchxlabs.ai/" className="flex mb-4 mx-2 items-center space-x-3" target="_blank">
              <img src={LaunchX} alt="LaunchX Logo" />
            </a>
          </div>
          <ul className="flex items-center justify-around mb-6 text-md sm:mb-0 ">
            <li>
              <a href="https://launchxlabs.ai/about/privacy" className="hover:underline px-2" target="_blank">Privacy Policy</a>
            </li>
            <li>
              <a href="https://launchxlabs.ai/about/terms" className="hover:underline px-2" target="_blank">Terms of Use</a>
            </li>
            <li>
              <a href="https://launchxlabs.ai/about/contact" className="hover:underline px-2" target="_blank">Contact</a>
            </li>
          </ul>
        </div>
        <hr className="my-2 border-black-600"/>
        <span className="block text-md text-center ">© 2024 <a href="https://launchxlabs.ai/" className="hover:text-black" target="_blank">LaunchX™</a>. All Rights Reserved.</span>
      </Container>
    </footer>
  )
}

export default Footer