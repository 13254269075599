/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from 'react'
import useIntersectionObserver from '../../components/userIntersectionObserver';

function PriceCard({ props }) {
  const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.3,
  });
  const [isMobile,setIsMobile] = useState(false);
  useEffect(()=>{
    const updateIsMobile = () => {
      if(window.innerWidth < 640) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    updateIsMobile();
    window.addEventListener('resize',updateIsMobile);
    return ()=>window.removeEventListener('resize',updateIsMobile);
  },[])

  return (
    <div ref={elementRef} className={`flex flex-col p-6 mx-auto max-w-lg text-center text-black-900 bg-white rounded-lg border border-black-100 shadow dark:border-black-600 xl:p-8 dark:bg-black-800 fadeInUp ${isVisible && !isMobile && 'visible'} ${isMobile && 'no-animation'}`} style={{ '--animation-duration': props.animation_duration }}>
      <h3 className="mb-4 cabin_sketch text-2xl">{props.title}</h3>
      <p className="font-light text-black-500 sm:text-lg">{props.desc}</p>
      <div className="flex justify-center items-baseline my-8">
        <span className="mr-2 cabin_sketch text-4xl">{props.price}</span>
        <span className="text-black-500">/month</span>
      </div>
      <ul role="list" className="mb-8 space-y-4 text-left">
        {
          props.benefits.map((benefit) => (
            <li key={benefit} className="flex items-center space-x-3">
              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20px" height="20px"><path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z" /></svg>
              <span>{benefit}</span>
            </li>
          ))
        }
        {props.disadvantages &&
          props.disadvantages.map((disadvantage) => (
            <li key={disadvantage} className="flex items-center space-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20px" height="20px"><path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)" /><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)" /></svg>
              <span>{disadvantage}</span>
            </li>
          ))
        }
      </ul>
      {
        props.title !== 'Free'&&
        <a href={props.paymentLink} className={`downloadButton font-medium rounded-lg text-md px-5 py-2.5 text-center`}>Get started</a>
      }
    </div>
  )
}

export default PriceCard