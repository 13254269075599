import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./Navbar.css";
import { IconContext } from "react-icons";

function Navbar() {
  const [sidebar, setSidebar] = useState(true);
  const location = useLocation();

  const showSidebar = () => setSidebar(sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#000000" }}>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            {SidebarData.map((item, index) => {
              const isActive = (location.pathname === item.path || ((location.pathname === '/' || location.pathname === '/signup') && item.path === '/loggedHome'));
              return (
                <li
                  key={index}
                  className={`${item.cName} ${isActive ? "active" : ""}`}
                >
                  <Link to={item.path}>
                    {item.icon}
                    <span className="lead neucha-regular orange-color">
                      {item.title}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
