import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material';
import useIntersectionObserver from '../../components/userIntersectionObserver';
import { pricing } from './pricingData';
import PriceCard from './PriceCard';

function Pricing() {
  const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const updateIsMobile = () => {
      if (window.innerWidth < 640) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    updateIsMobile();

    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, [])
  return (
    <Container>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 ref={elementRef} className={`mb-4 cabin_sketch text-4xl text-black-900 fadeInUp ${isVisible && !isMobile && 'visible'} ${isMobile && 'no-animation'}`} style={{ '--animation-duration': '0.7s' }}>Designed for business teams like yours</h2>
          {/* <p ref={elementRef} className={`mb-5 font-light text-black-500 sm:text-xl dark:text-black-400 fadeInUp ${isVisible && !isMobile && 'visible'} ${isMobile && 'no-animation'}`} style={{ '--animation-duration': '1s' }}>Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p> */}
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          {
            pricing.map((priceDetails) => (
              <PriceCard key={priceDetails.title} props={priceDetails} />
            ))
          }
        </div>
      </div>
    </Container>
  )
}

export default Pricing