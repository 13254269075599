import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "querystring-es3";
import axios from "axios";
import "./socialauth.css";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const REACT_ENV = process.env.REACT_APP_LOCAL_ENV;
const REACT_PORT = process.env.REACT_APP_REDIRECT_PORT;

const SocialAuth = () => {
  let location = useLocation();

  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const values = queryString.parse(location.search);

    const urlParams = new URLSearchParams(location.search);
    const code2 = urlParams.get("code");

    const code = code2;

    if (code) {
      onGogglelogin();
    }
    window.parent.postMessage({ isLoggedIn: true }, "*");
  }, []);

  const googleLoginHandler = (code) => {
    return axios
      .get(
        `${BACKEND_API_URL}/api/auth/google${code}&ENV=${REACT_ENV}&PORT=${REACT_PORT}`
      )
      .then((res) => {
        localStorage.setItem("goggleFirstName", res.data.user.first_name);
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);

        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        var expires = expirationDate.toUTCString();
        var cookieString = `accessToken=${res.data.access}; expires=${expires}; path=/`;
        document.cookie = cookieString;

        navigate(`/loggedHome`);
        window.location.reload();
        return res.data;
      })
      .catch((err) => {
        // console.log("api url: ", `${BACKEND_API_URL}/api/auth/google/${code}`);
        console.log(err);
        setError(err);
        return err;
      });
  };

  const onGogglelogin = async () => {
    const response = await googleLoginHandler(location.search);

    // if (response.data.access) {
    //     console.log("Success!");
    //     localStorage.setItem('isLoggedIn', true);
    //     window.location.reload();
    console.log(
      "I have logged in with this username devs",
      localStorage.getItem("goggleFirstName")
    );
    //   navigate("");

    // } else {
    //     console.log("Error to login");
    // }
  };

  return (
    <div className="loading-icon-container">
      <div className="loading-icon">
        <div className="loading-icon__circle loading-icon__circle--first"></div>
        <div className="loading-icon__circle loading-icon__circle--second"></div>
        <div className="loading-icon__circle loading-icon__circle--third"></div>
        <div className="loading-icon__circle loading-icon__circle--fourth"></div>
      </div>
      <div className="ml-2">Just a moment</div>
    </div>
  );
};

export default SocialAuth;
