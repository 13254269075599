import { getCookie } from "../utils";
const baseUrl = process.env.REACT_APP_BACKEND_API_URL;
export const addContext = (name, content, accessToken) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      offering_name: name,
      offering_text: content,
    }),
    redirect: "follow",
  };

  fetch(`${baseUrl}/linkedin/context_add_text`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (result.message && result.message === "user not found") {
        return {
          error: true,
          message: "Session expired, please login again",
          data: result,
        };
      }
      return result;
      //   getContexts();
      //   setNewContext({
      //     context_name: "",
      //     content: "",
      //   });
    })
    .catch((error) => console.error("error", error));
};
