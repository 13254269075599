import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { ModalTitle } from 'react-bootstrap';
import './form_offerings2.css';



const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

function getCookie(name) {
  // Split cookies into individual parts
  var cookies = document.cookie.split(";");

  // Iterate over each cookie to find the one with the specified name
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];

    // Remove leading spaces
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }

    // Check if this cookie has the specified name
    if (cookie.indexOf(name + "=") === 0) {
      // Extract and return the value of the cookie
      return cookie.substring(name.length + 1, cookie.length);
    }
  }

  // If cookie with specified name is not found, return null
  return null;
}

// TODO: Change a_t of form_testing, messages and Home from hard code to cookie
let a_t = getCookie("accessToken");
// let a_t = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5ODM2OTc5LCJpYXQiOjE3MTc2NzY5NzksImp0aSI6ImJkNWFiYjk0MDMwMTQwMGQ4ZDc4YWI4M2E4ZTk0MmUwIiwidXNlcl9pZCI6MTkxfQ.0TH3NrPr7SavhWmDG_Pt8smQG59e7nb5SJfOu0_vC-8";


function UpdateModal(props) {
  const updateLinkedIn = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append('Accept', '*/*');
      myHeaders.append('Authorization', `Bearer ${a_t}`);
      myHeaders.append('Content-Type', '*/*');
      const data = {
        confirmation: false
      };
      var requestOptions = {
        headers: myHeaders,
        method: 'POST',
        body: JSON.stringify(data),
      };
      let response = await fetch(`${baseUrl}/linkedin/post_user_linkedin_confirmation`, requestOptions);
      let responseBody = await response.json();
      if (responseBody && responseBody.status === 'success') {
        // console.log("LinkedIn ID de-registered");
      } else {
        // console.log("LinkedIn ID couldn't de-register");
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <ModalTitle className=' neucha-regular'>Update Registered LinkedIn Profile</ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <p className=' neucha-regular'>
          Go to your LinkedIn profile and Register again using EngageX extension.
        </p>
      </Modal.Body>
      <Modal.Footer className=' neucha-regular'>
        {/* <button className='btn btn-outline-warning'  onClick={props.onHide}>Close</button> */}
        <button className='btn btn-outline-warning' onClick={()=> {
          updateLinkedIn();
          window.location.href = `https://www.linkedin.com`;
          props.onHide();
        }}>Click to Confirm</button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateModal